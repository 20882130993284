<template>
  <van-skeleton title :row="7" :loading="loading">
    <div class="goods-details">
      <van-swipe
        :autoplay="3000"
        class="top-swipe"
        indicator-color="white"
        @change="onChange"
      >
        <van-swipe-item
          v-for="(item, index) in state.rotationPicture"
          :key="index"
        >
          <div class="top-swipe-item">
            <img v-if="item" :src="item" alt="" />
            <img
              v-else
              src="https://wca.ujiaku.com/upload/app_defaultImg/noPic.png"
            />
          </div>
        </van-swipe-item>
        <template #indicator>
          <div class="custom-indicator">
            {{ active + 1 }}/{{ state.rotationPicture.length }}
          </div>
        </template>
      </van-swipe>
      <div class="goods-main">
        <div class="head-box">
          <div class="title">{{ state.materialName }} {{ state.itemNo }}</div>
          <div class="flex-box">
            <div class="price-box">
              <span>￥</span>
              <span class="price">{{ state.sellingPrice }}</span>
              <span class="unit">/{{ state.salesUnitName }}</span>
            </div>
          </div>
          <div class="tag-box">
            <span v-if="state.brandName">
              <van-tag
                v-if="state.brandName"
                plain
                type="primary"
                style="margin:0 2px"
                >{{ state.brandName }}</van-tag
              >
            </span>
            <span v-if="styleInfo.length">
              <van-tag
                plain
                type="success"
                style="margin:0 2px"
                v-for="item in styleInfo"
                :key="item"
                >{{ item }}</van-tag
              >
            </span>
          </div>
        </div>
        <div class="collocationList" v-if="state.collocationList.length">
          <div class="main-title">
            推荐搭配
          </div>
          <Carousel :imgs="state.collocationList">
            <template #default="scope">
              <goods-item
                :good-info="scope.item"
                @clickItem="handleViewMore"
                :self-clik="false"
              />
            </template>
          </Carousel>
        </div>
        <div class="tabs-box">
          <van-tabs>
            <van-tab title="商品详情">
              <TransformHTML :contents="state.details"></TransformHTML>
            </van-tab>
            <van-tab title="规格参数">
              <div class="base-info">
                <div class="main-title padd16">基本信息</div>
                <div class="info-block">
                  <div class="info-title">分类</div>
                  <div class="info-value">{{ state.categoryName }}</div>
                </div>
                <div class="info-block">
                  <div class="info-title">规格</div>
                  <div class="info-value">{{ state.specs }}</div>
                </div>
                <div class="info-block">
                  <div class="info-title">采购单位</div>
                  <div class="info-value">{{ state.purchasingUnitName }}</div>
                </div>
                <div class="info-block">
                  <div class="info-title">换算率</div>
                  <div class="info-value">{{ state.conversionRate }}</div>
                </div>
                <div class="info-block">
                  <div class="info-title">编码</div>
                  <div class="info-value">{{ state.materialCode }}</div>
                </div>
              </div>
              <div class="prop-info">
                <div class="main-title padd16">商品属性</div>
                <div
                  class="info-block"
                  v-for="(item, index) in state.properties"
                  :key="index"
                >
                  <div class="info-title">{{ item.propertyName }}</div>
                  <div class="info-value">{{ item.propertyValue }}</div>
                </div>
              </div>
            </van-tab>
          </van-tabs>
        </div>
      </div>
    </div>
  </van-skeleton>
</template>

<script setup>
import Carousel from "@/components/Carousel.vue";
import GoodsItem from "@/components/GoodsItem.vue";
import { Dialog, Toast } from "vant";
import { computed, reactive, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { getShopMaterialWithoutToken } from "../../api/common";
import TransformHTML from "../../components/transformHTML.vue";
const route = useRoute();
const router = useRouter();
const materialId = route.query.id;
const materialStockId = route.query.materialStockId;
const styleInfo = computed(() => {
  let temp = state.properties.find((item) => item.propertyName == "风格");
  if (temp) {
    return temp.propertyValue.split(",");
  } else {
    return [];
  }
});

watch(
  () => route.query.id,
  (val) => {
    if (val) {
      loadGoodsDetails(val);
    }
  }
);

const selectedSpec = ref();
const number = ref(1);
const active = ref(null);
const show = ref(false);
const loading = ref(true);
const state = reactive({
  conversionRate: "",
  materialCode: "",
  rotationPicture: [],
  brandName: "",
  categoryName: "",
  details: "",
  itemNo: "",
  materialName: "",
  specs: "",
  properties: [],
  salesUnitName: "",
  purchasingUnitName: "",
  sellingPrice: "",
  qrCodeUrl: "",
  collocationList: [],
});

const onChange = (index) => {
  active.value = index;
};
const handleViewMore = (id) => {
  router.push({
    name: "goodsShare",
    query: {
      id: id,
      materialStockId: materialStockId,
    },
  });
};
const loadGoodsDetails = async (materialId) => {
  loading.value = true;
  Toast.loading({ duration: 300, message: "加载中" });
  let data = await getShopMaterialWithoutToken({
    materialId: materialId,
    materialStockId: materialStockId,
  });
  state.materialCode = data.materialCode;
  state.conversionRate = data.conversionRate;
  state.rotationPicture = data.rotationPicture;
  state.brandName = data.brandName;
  state.categoryName = data.categoryName;
  state.details = data.details;
  state.itemNo = data.itemNo;
  state.materialName = data.materialName;
  state.specs = data.specs;
  state.properties = data.properties;
  state.salesUnitName = data.salesUnitName;
  state.purchasingUnitName = data.purchasingUnitName;
  state.sellingPrice = data.sellingPrice;
  state.qrCodeUrl = data.qrCodeUrl;
  state.collocationList = data.collocationList;
  if (!state.rotationPicture.length) {
    state.rotationPicture = [""];
  }
  Toast.clear();
  loading.value = false;
};
loadGoodsDetails(materialId);
</script>

<style lang="scss" scoped>
.goods-details {
  text-align: left;
  .top-swipe-item {
    height: 280px;
    width: 100%;
    img {
      height: 280px;
      width: 100%;
    }
  }
  .custom-indicator {
    position: absolute;
    right: 5px;
    bottom: 5px;
    padding: 2px 5px;
    font-size: 12px;
    background: rgba(0, 0, 0, 0.1);
  }
  .goods-main {
    position: relative;
    background: rgb(250, 250, 250);
    .head-box {
      background: #fff;
      padding: 16px;
      .title {
        color: #171725;
        font-weight: 500;
        font-size: 16px;
      }
      .flex-box {
        margin: 12px 0;
        display: flex;
        justify-content: space-between;
        span:nth-of-type(1) {
          font-size: 18px;
          color: red;
        }
        span:nth-of-type(2) {
          font-size: 28px;
          color: red;
        }
        span:nth-of-type(3) {
          margin-left: 5px;
          color: #696974;
          font-size: 12px;
        }
      }
      .tag-box {
        display: flex;
        justify-content: flex-end;
      }
    }
    .collocationList {
      padding: 16px;
    }
    .tabs-box {
      font-size: 14px;
      margin-top: 16px;
      .info-block {
        background: #fff;
        padding: 16px 16px;
        display: flex;
        .info-title {
          min-width: 130px;
          color: #92929d;
        }
        .info-value {
          margin-left: 18px;
          color: #171725;
        }
      }
    }
  }
}
.main-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #171725;
  margin-bottom: 10px;
}
.padd16 {
  padding: 16px;
  background: #fff;
  position: relative;
  &::before {
    content: "";
    width: 4px;
    height: 12px;
    background-color: #4d6ff0;
    position: absolute;
    left: 0px;
    top: 20px;
  }
}
.btm-submit-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  background-color: #fff;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  .btm-box {
    padding: 6px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    font-size: 14px;
    .back-home {
      text-align: center;
      display: flex;
      flex-direction: column;
    }
  }
}
.cart-wrapper {
  padding: 16px;
  min-height: 450px;
  display: flex;
  flex-direction: column;
  text-align: left;
  .cart-main {
    flex: 1;
    .cart-head-box {
      display: flex;
      .pic {
        margin-right: 10px;
        img {
          width: 124px;
          height: 124px;
          object-fit: cover;
        }
      }

      .description-title {
        color: #171725;
        font-weight: 500;
        font-size: 16px;
      }
      .spec {
        font-size: 12px;
        color: #696974;
        margin: 10px 0px;
      }
      .price {
        color: #696974;
        font-size: 14px;
      }
    }
    .spec-select {
      color: #696974;
      font-size: 14px;
      &::after {
        content: ""; /*设置内容为空*/
        height: 0; /*高度为0*/
        line-height: 0; /*行高为0*/
        display: block; /*将文本转为块级元素*/
        visibility: hidden; /*将元素隐藏*/
        clear: both;
      }
    }
    .number-check {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      color: #696974;
      font-size: 14px;
    }
  }
}
.active {
  float: left;
  padding: 4px 12px;
  background: #d6e4ff;
  margin-bottom: 10px;
  border-radius: 16px;
  font-size: 14px;
  color: #4d6ff0;
}
.Classification {
  float: left;
  padding: 4px 12px;
  background: #f7f7f7;
  margin-bottom: 10px;
  border-radius: 16px;
  font-size: 14px;
}
</style>
